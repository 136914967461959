import getMatch from "@/game-deadlock/fetches/match.mjs";
import {
  getMatchlist,
  getMatchlistMeta,
} from "@/game-deadlock/fetches/matchlist.mjs";
import getSteam from "@/game-deadlock/fetches/steam.mjs";
import { steam3to64 } from "@/util/steam.mjs";

export default async function fetchData([steamId, matchId]: FixedLengthArray<
  string,
  2
>): Promise<void> {
  (async () => {
    const matchlist = await getMatchlist({ steamId });
    if (!matchlist) return;
    // We only need 5 matches for comparison stats if they haven't been loaded already
    for (let i = 0; i <= 5; i += 1) {
      const matchId = matchlist[i];
      if (!matchId) break;
      // No need to await, allow the match page to render ASAP
      // The match data here is purely for comparison stats and doesn't need to render block
      getMatchlistMeta({ steamId, matchId });
    }
  })();
  await Promise.all([
    getSteam({
      steamId3: steamId,
      steamId64: steam3to64(Number(steamId)),
    }),
    (async (): Promise<void> => {
      const match = await getMatch({ matchId });
      if (!Array.isArray(match?.players)) return;
      // The data being fetched are below the fold in this page and can update async
      for (const { account_id: steamId3 } of match.players)
        getSteam({
          steamId3,
          steamId64: steam3to64(steamId3),
        });
    })(),
  ]);
}
